.tech_para {
  color: #333;
  margin-bottom: 40px;
}
.tech_title {
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  margin:0;
  padding:0;
  line-height: 1;
  font-weight: 300;
}

.tech_content {
  font-family: Vollkorn, Georgio;
  font-size: 16px;
  line-height: 160%;
  border-left: 5px solid #ddd;
  padding:10px 0 10px 20px;
  margin: 10px 0;
}
.tech_content ul {
  list-style: none;
  padding:0;
  margin:0;
}


.tech_content li {
  line-height: 1.5;
  display: block;
  align-items: center;
  box-sizing: border-box;
  max-width: 720px;
  vertical-align: top;
  
}
.tech_content span.subtitle {
	font-weight: 600;
	margin-right: 10px;
}
