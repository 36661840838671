.arrow-icon {
  animation: bounce 1s infinite;
  cursor: pointer;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translate(0, 0);
  }
  40% {
    transform: translate(6px, -6px);
  }
  60% {
    transform: translate(3px, -3px);
  }
}
